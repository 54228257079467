.app {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	width: 100%;
	height: 100%;
}

.interface {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 1000;
	width: 100%;
	height: 100%;
}

.body {
	overflow-x: hidden;
}

.render {
	width: 100%;
	height: 100%;
}
@media screen and (min-width: 1px) and (max-width: 1100px) {
	/* .render {
		height: 60vh;
	}
	.render canvas {
		max-height: 60vh;
	} */
}

.tracker-item {
	margin-bottom: 5px;
	padding: 8px 5px 5px 10px;
	border-left-style: solid;
	border-left-width: 3px;
	background-color: #e9e9e9;
}

.tracker-item.variable-item {
	border-left-color: #9510e0;
}

.tracker-item.entity-item {
	border-left-color: #00ff9a;
	cursor: pointer;
}

.tracker-item.entity-item:hover {
	background-color: silver;
}

.tracker-item.entity-item.active {
	background-color: #1f191b;
	color: #fff;
}

.state-label {
	height: 45px;
	min-width: 200px;
	padding-top: 12px;
	padding-left: 16px;
	border-left: 2px solid #00ff9a;
	border-radius: 3px;
	background-color: #1f191b;
	font-family: "Open Sans", sans-serif;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
}

.tracker-wrap.config-items-wrap {
	padding-top: 15px;
}

.tracker-wrap.variables-wrap {
	margin-top: 15px;
}

.tracker-title {
	font-family: "Open Sans", sans-serif;
	font-size: 10px;
	line-height: 12px;
	font-weight: 700;
}

.tracker-title.selected-var-title {
	margin-bottom: 10px;
}

.entity-value {
	font-size: 10px;
	font-weight: 600;
}

.state-label-wrapper {
	position: relative;
}

.state-nav-wrapper {
	position: fixed;
	left: auto;
	top: auto;
	right: 20px;
	bottom: 25px;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.tracker-list {
	margin-top: 5px;
}

.state-list-item {
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 16px;
	font-family: "Open Sans", sans-serif;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	cursor: pointer;
}

.state-list-item:hover {
	color: #00ff9a;
}

.debug-wrap {
	position: absolute;
	left: 20px;
	top: 90px;
	z-index: 1000;
	width: 200px;
	padding: 10px;
	border-top: 3px solid #000;
	background-color: #fff;
}

.debug-wrap.debug-wrap-config {
	position: fixed;
	left: auto;
	top: auto;
	right: 20px;
	bottom: 100px;
}

.debug-wrap.debug-floor-config {
	position: fixed;
	left: auto;
	top: auto;
	right: 20px;
	bottom: 300px;
	display: none;
}

.debug-wrap.selected-vars-wrap {
	position: fixed;
	left: 235px;
	top: 90px;
	right: auto;
	padding-bottom: 0px;
	border-radius: 3px;
	background-color: #1f191b;
	color: #fff;
}

.state-button {
	width: 45px;
	height: 45px;
	margin-left: 5px;
	border-radius: 3px;
	background-color: #fff;
	cursor: pointer;
}

.state-button:hover {
	background-color: #616161;
}

.state-button.next-button {
	background-image: url("../images/next-arrow.svg");
	background-position: 55% 50%;
	background-repeat: no-repeat;
}

.state-button.prev-button {
	background-image: url("../images/prev-arrow.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.state-selector-list {
	padding: 10px 0px;
	border-radius: 3px;
	background-color: #1f191b;
}

.reset-button {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 45px;
	height: 45px;
	border-radius: 3px;
	background-color: #fff;
	background-image: url("../images/reset-icon.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
}

.reset-button:hover {
	background-color: #616161;
}

.show-floor-button {
	height: 45px;
	margin-right: 15px;
	padding-top: 12px;
	padding-right: 12px;
	padding-left: 40px;
	border-radius: 3px;
	background-color: #fff;
	background-image: url("../images/radial2.svg");
	background-position: 13px 50%;
	background-repeat: no-repeat;
	font-family: "Open Sans", sans-serif;
	cursor: pointer;
}

.show-floor-button:hover {
	background-color: #1f191b;
	color: #fff;
}

.show-floor-button.active {
	background-image: url("../images/radial2-checked.svg");
}

.config-list-item {
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 25px;
	background-image: url("../images/radial.svg");
	background-position: 0px 50%;
	background-repeat: no-repeat;
	font-family: "Open Sans", sans-serif;
	cursor: pointer;
}

.config-list-item:hover {
	color: #f0524a;
}

.config-list-item.active {
	background-image: url("../images/radial-checked.svg");
}

.entity-title {
	font-size: 10px;
	line-height: 12px;
	font-weight: 600;
}

.debug.hidden {
	display: none;
}

.slide {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	width: 100%;
}

.content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: 100%;
}
@media screen and (min-width: 1px) and (max-width: 1100px) {
	.content {
		width: 100%;
	}
}
@media screen and (min-width: 1101px) and (orientation: landscape) {
	.content.auto-fade,
	.content.fade {
		position: relative;
		z-index: 1;
	}
}

.content.full-width-content {
	width: 100%;
}

.content.full-width-content.intro-content {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.overlay-instruction-wrapper {
	position: absolute;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 20px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.close-button {
	position: absolute;
	top: 0%;
	right: 0px;
	bottom: auto;
	width: 45px;
	height: 45px;
	background-color: #f0524b;
	background-image: url("../images/close-icon.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
}

.close-button:hover {
	background-color: #0a2342;
}

.close-button.close-wide {
	right: 7.5%;
}

.gallery-content {
	position: relative;
	width: 97%;
	height: 95%;
	background-color: #fff;
	box-shadow: 3px 3px 6px -3px #031225, 1px 1px 2px -1px #031225;
}

.gallery-content.wide {
	width: 100%;
}

.gallery-overlay {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 1500;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.gallery-overlay.hidden {
	display: none;
}

.instruction-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: auto;
	margin-bottom: 5px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	border-radius: 4px;
	background-color: #0a2342;
}

.overlay-fade {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	width: 100%;
	height: auto;
	background-color: #0a2342;
	opacity: 0.7;
}

.instruction {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	/* margin-bottom: 3px; */
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

@media screen and (min-width: 1px) and (max-width: 1100px) {
	.instruction {
		justify-content: flex-start;
	}

	.inst-middle-scroll {
		width: 15px;
	}
}

.instruction._360-icon {
	background-image: url("../images/360-icon.svg");
	background-position: 8px 50%;
	background-repeat: no-repeat;
}

.instruction-highlight {
	color: #ffcf40;
}

.body {
	background-color: #ecf0ef;
	font-family: "Favorit Trial", sans-serif;
	color: #003e34;
}

.selected-vars-item {
	margin-bottom: 10px;
}

.content-fade {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: -1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
}
@media screen and (min-width: 1px) and (max-width: 1100px) {
	.content-fade {
		width: 100%;
		height: calc(40vh + 22px);
		top: auto;
		bottom: 0;
		left: 0;
		right: 0;
		background: #edf0ef !important;
		box-shadow: 0 80px 40px 135px #edf0ef;
	}
}
@media screen and (min-width: 1101px) {
	.content-fade {
		left: 65px;
	}
}

.content-top-fade {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: -1;
	height: 15%;
}

.burger-button-wrapper {
	position: fixed;
	left: 0px;
	top: 0px;
	bottom: 0px;
	z-index: 1100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.burger-icon {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 57px;
	height: 57px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	/* background-color: #ffffff; */
	background-image: url("../images/burger-icon.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
}
@media screen and (min-width: 1101px) {
	.burger-icon {
		margin-left: 9px;
		margin-top: 30px;
		width: 45px;
		height: 45px;
	}
	.burger-icon:hover {
		border-radius: 3px;
		background-color: #e0e0e0;
	}
}

@media screen and (min-width: 1px) and (max-width: 1100px) {
	.burger-icon {
		background-color: #ffffff;
	}
}

.burger-icon.close-icon {
	background-image: url("../images/burger-close-icon.svg");
}

.content-side {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 75px;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}
@media screen and (min-width: 1px) and (max-width: 1100px) {
	.content-side {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
	}
}

.content-main {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	max-width: 330px;
	padding-top: 80px;
	padding-bottom: 40px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
}
@media screen and (min-width: 1px) and (max-width: 1100px) {
	.content-main {
		padding-bottom: 20px;
		padding-right: 20px;
		padding-left: calc(19vw + 39px);
		padding-top: calc(50vh + 20px);
		max-width: 100%;
	}
	.content-main__scroll {
		overflow-y: scroll;
		padding-right: 25px;
	}
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
	.content-main {
		padding-bottom: 30px;
		padding-right: 30px;
		padding-left: calc(18vw + 39px);
		padding-top: calc(60vh + 20px);
		min-width: 100vw;
	}
}
@media screen and (min-width: 1101px) {
	.content-main {
		padding-left: 32px;
	}
}

.content-main.contact-content {
	max-width: none;
	/* padding-right: 110px; */
	padding-right: 60px;
	padding-left: 60px;
	padding-top: 40px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.section-graphic.feeling-graphic {
	background-image: url("../images/feeling-icon.svg");
	background-position: 50% 0%;
	background-size: cover;
	background-repeat: no-repeat;
}

.section-graphic.detail-graphic {
	background-image: url("../images/detail-graphic.svg");
	/* background-position: 100% 130%; */
	background-position: 150% 183%;
	background-repeat: no-repeat;
	width: 100%;
	height: 70px;
	margin-top: -10px;
}
.section-graphic.detail-graphic.contactus-graphic {
	transform: translate(0, -20px);
}
@media screen and (min-width: 1px) and (max-width: 767px) {
	.section-graphic.detail-graphic {
		height: 75px;
		left: 37px;
	}
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
	.section-graphic.detail-graphic {
		height: 75px;
		left: 37px;
	}
	.section-graphic-text.contactus {
		margin-left: 70px;
	}
}

@media screen and (min-width: 768px) {
	.section-graphic.detail-graphic,
	.section-graphic.community-graphic {
		position: relative;
		z-index: 1;
	}
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
	.section-graphic.community-graphic {
		height: 41px !important;
		width: 70px !important;
		transform: translate(40px, 15px);
	}
	.section-graphic-text.the-community {
		transform: translate(46px, 40px);
	}
}
@media screen and (min-width: 1024px) and (max-width: 1100px) {
	.section-header.in-good-company {
		max-height: 120px;
	}
	div.section-graphic.community-graphic {
		height: 78px !important;
		transform: translate(60px, 15px);
	}
	.section-graphic-text.the-community {
		transform: translate(61px, 61px);
		width: 14px;
	}
}

.section-graphic.community-graphic {
	background-image: url("../images/community-graphic.svg");
	background-repeat: no-repeat;
	/* background-position: 100% -500%;
	background-size: cover; */
	background-position: center 42px;
	background-size: 100% auto;
	height: 78px;
	width: 60px;
}
@media screen and (min-width: 1px) and (max-width: 767px) {
	.section-graphic.community-graphic {
		background-repeat: no-repeat;
		background-position: center 42px;
		background-size: 100% auto;
		height: 78px;
		width: 60px;
		transform: translate(12px, 0);
	}
}
@media screen and (min-width: 768px) {
	.section-graphic.community-graphic {
		background-position: 6px 4px;
		background-size: 65px auto;
		height: 69px;
		width: 75px;
	}
}
@media screen and (min-width: 1101px) {
	.section-graphic.community-graphic {
		background-position: -10px 26px;
		background-size: 65px auto;
	}
}

.section-text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 200px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.section-text {
	width: 100%;
	margin-bottom: 13px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px solid #003e34;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	direction: ltr;
	font-weight: 500;
	white-space: nowrap;
}

.section-header-wrapper {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-bottom: 10px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.section-header {
	margin-top: 0px;
	margin-bottom: 40px;
	text-align: right;
	text-decoration: underline;
	white-space: nowrap;
}

.section-header.fulfillment-at-work {
	width: 116px;
	height: 13vw;
	max-height: 257px;
	margin-bottom: 40px;
	background-image: url("../images/fulfillment-at-work.svg");
	background-position: 0% 0%;
	background-size: contain;
	background-repeat: no-repeat;
}
@media screen and (min-width: 1px) and (max-width: 767px) {
	.section-header.fulfillment-at-work {
		width: 19vw;
		height: 45vh;
		max-height: 162px;
		margin: 0;
		position: absolute;
		left: 15px;
		top: calc(50vh + 20px);
	}
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
	.section-header.fulfillment-at-work {
		width: 14vw;
		height: 35vh;
		max-height: 192px;
		margin: 0;
		position: absolute;
		left: 29px;
		top: calc(60vh + 20px);
	}
}

.section-header.built-for-purpose {
	width: 116px;
	height: 13vw;
	max-height: 193px;
	background-image: url("../images/bult-for-purpose.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}
@media screen and (min-width: 1px) and (max-width: 767px) {
	.section-header.built-for-purpose {
		width: 19vw;
		height: 45vh;
		margin: 0;
		position: absolute;
		left: 15px;
		top: calc(50vh + 20px);
		background-position: center top;
	}
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
	.section-header.built-for-purpose {
		width: 14vw;
		height: 35vh;
		margin: 0;
		position: absolute;
		left: 29px;
		top: calc(60vh + 20px);
		background-position: center top;
	}
}

.section-header.in-good-company {
	width: 115px;
	height: 13vw;
	max-height: 222px;
	background-image: url("../images/in-good-company.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}
@media screen and (min-width: 1px) and (max-width: 767px) {
	.section-header.in-good-company {
		width: 19vw;
		height: 45vh;
		margin: 0;
		position: absolute;
		left: 15px;
		top: calc(50vh + 20px);
		background-position: center top;
	}
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
	.section-header.in-good-company {
		width: 14vw;
		height: 35vh;
		margin: 0;
		position: absolute;
		left: 29px;
		top: calc(60vh + 20px);
		background-position: center top;
	}
}

.section-header.commute-happy {
	width: 115px;
	height: 13vw;
	max-height: 238px;
	background-image: url("../images/commute-happy.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}
@media screen and (min-width: 1px) and (max-width: 767px) {
	.section-header.commute-happy {
		width: 19vw;
		height: 45vh;
		margin: 0;
		position: absolute;
		left: 15px;
		top: calc(50vh + 20px);
		background-position: center top;
	}
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
	.section-header.commute-happy {
		width: 14vw;
		height: 35vh;
		margin: 0;
		position: absolute;
		left: 29px;
		top: calc(60vh + 20px);
		background-position: center top;
	}
}

.section-header.specification-title {
	width: 70px;
	height: 318px;
	background-image: url("../images/specification-title.svg");
	background-position: 0% 0%;
	background-repeat: no-repeat;
}

.section-header.travel-better-header {
	width: 136px;
	height: 153px;
	background-image: url("../images/travel-better-header.svg");
	background-position: 0px 0px;
	background-repeat: no-repeat;
}

.section-header.occupier-header {
	width: 119px;
	height: 230px;
	background-image: url("../images/occupiers-title.svg");
	background-position: 0px 0px;
	background-repeat: no-repeat;
}

.content-mid-wrapper {
	margin-top: 80px;
}

.rotate {
	-webkit-transform: rotate(-151deg);
	-ms-transform: rotate(-151deg);
	transform: rotate(-151deg);
}

.section-bottom-wrapper {
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.copy {
	max-width: 700px;
	margin-bottom: 0px;
}

.section-subhead {
	margin-top: 0px;
	margin-bottom: 0px;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	letter-spacing: -0.2px;
}

.section-nav-item {
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 24px;
	line-height: 30px;
	text-decoration: underline;
	cursor: pointer;
}

.section-nav-item:hover {
	color: #e18e75;
}

.slide-nav-wrapper {
	position: fixed;
	left: auto;
	top: auto;
	right: 50px;
	bottom: 52px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: 46px;
	padding-right: 35px;
	padding-left: 21px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 400;
	background-image: url("../images/slide-nav-arrow.svg");
	background-position: 100% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
}
@media screen and (min-width: 768px) {
}

.slide-nav-wrapper:hover {
	background-image: url("../images/pink-arrow.svg");
	color: #e18e75;
}

.slide-nav-wrapper:not(.intro-nav-wrapper):hover {
	background-color: #ffffff;
	color: #e18e75;
}

.slide-nav-wrapper.intro-nav-wrapper {
	background-image: url("../images/green-arrow.svg");
	right: 50px;
	bottom: 52px;
	width: 109px;
	height: 49px;
	justify-content: flex-start;
	background-color: #fff;
	background-position: right 15px top 14px;
	background-size: 12px;
}

@media screen and (min-width: 768px) {
	.slide-nav-wrapper.intro-nav-wrapper {
		bottom: 60px;
	}
}

.slide-nav-wrapper:not(.intro-nav-wrapper) {
	display: none;
	padding-right: 48px;
	font-size: 16px;
	color: #003e34;
	background-color: #8ad5a7;
	background-position: right 20px top 12px;
	background-size: 12px;
}
@media screen and (min-width: 768px) {
	.slide-nav-wrapper:not(.intro-nav-wrapper) {
		display: flex;
	}
}

.slide-nav-wrapper.intro-nav-wrapper .intro-text {
	font-size: 20px;
	line-height: 24px;
	position: fixed;
	left: 50px;
	bottom: 99px;
	right: 50px;
	text-align: right;
	padding: 15px;
	color: #8ad5a7;
	background: #003e34;
}

.slide-nav-wrapper.intro-nav-wrapper .intro-text::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: #003e34;
}

@media screen and (min-width: 768px) {
	.slide-nav-wrapper.intro-nav-wrapper .intro-text {
		padding: 0;
		bottom: 109px;
		background: transparent;
	}
	.slide-nav-wrapper.intro-nav-wrapper .intro-text::before {
		display: none;
	}
}

.slide-nav-wrapper.intro-nav-wrapper .intro-text span {
	text-align: right;
	position: relative;
	line-height: 1.33;
}

@media screen and (min-width: 768px) {
	.slide-nav-wrapper.intro-nav-wrapper .intro-text span {
		/* padding: 8px 25px 7px 25px; */
		line-height: 52px;
		padding-top: 5px;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 10px;
		background: #003e34;
	}
}

.slide-nav-wrapper.intro-nav-wrapper .intro-text br {
	display: none;
}

@media screen and (min-width: 768px) {
	.slide-nav-wrapper.intro-nav-wrapper .intro-text {
		font-size: 35px;
		line-height: 44px;
		left: auto;
		margin-bottom: 8px;
	}
	.slide-nav-wrapper.intro-nav-wrapper .intro-text br {
		display: inline;
	}
}

/* .slide-nav-wrapper.intro-nav-wrapper:hover {
	background-image: url("../images/pink-arrow.svg");
	color: #e18e75;
} */

.slide-nav-arrow {
	width: 22px;
	height: 100%;
	margin-left: 5px;
	background-image: url("../images/slide-nav-arrow.svg");
	background-position: 100% 50%;
	background-repeat: no-repeat;
}

.slide-nav-arrow.green-arrow {
	background-image: url("../images/green-arrow.svg");
}

.slide-nav-text {
	font-size: 18px;
	font-weight: 400;
}

.top-right-wrapper {
	position: fixed;
	left: auto;
	top: 40px;
	right: 30px;
	bottom: auto;
}

.compass {
	width: 36px;
	height: 36px;
}
@media screen and (min-width: 1px) and (max-width: 768px) {
	.compass {
		margin-top: -26px;
		margin-right: -22px;
	}
}

.content-right-fade {
	position: fixed;
	left: auto;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: -1;
	width: 120px;
}

.content-bottom-fade {
	position: fixed;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: -1;
	height: 120px;
}

.check-component {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
}

.check-component:hover {
	color: #e18e75;
}

.check-component.plan-view-checks {
	margin-top: 45px;
}

.check {
	width: 16px;
	height: 16px;
	margin-right: 10px;
	background-image: url("../images/check.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.check.active {
	background-image: url("../images/check-icon.svg");
}

.check-text {
	font-weight: 500;
	text-decoration: underline;
}

.section-graphic-text {
	width: 17px;
}
@media screen and (min-width: 1101px) {
	.section-graphic-text {
		display: none;
	}
}

.section-graphic-text.the-feeling {
	height: 84px;
	background-image: url("../images/thefeeling.svg");
	background-position: 50% 100%;
	background-size: contain;
	background-repeat: no-repeat;
}

.section-graphic-text.the-detail {
	height: 73px;
	background-image: url("../images/thedetail.svg");
	background-position: 50% 100%;
	background-size: contain;
	background-repeat: no-repeat;
}

.section-graphic-text.the-community {
	height: 118px;
	background-image: url("../images/thecommunity.svg");
	background-position: 0px 0px;
	background-size: contain;
	background-repeat: no-repeat;
}

.section-graphic-text.contactus {
	width: 21px;
	height: 79px;
	background-image: url("../images/contactus.svg");
	background-position: 0px 0px;
	background-size: contain;
	background-repeat: no-repeat;
}

@media screen and (min-width: 1px) and (max-width: 1100px) {
	.section-graphic-text.the-detail,
	.section-graphic-text.the-community {
		background: none;
		position: relative;
	}
	.section-graphic-text.the-community::before,
	.section-graphic-text.the-detail::before {
		content: "Overview";
		transform: rotate(270deg);
		display: block;
		position: fixed;
		bottom: 54px;
		left: 30px;
		width: 0;
		height: 0;
		white-space: nowrap;
		font-size: 16px;
		font-weight: 500;
	}
	.section-graphic-text.the-community::before {
		content: "Stockley Park";
		color: #e18e75;
		bottom: 48px;
		left: 42px;
		font-size: 12px;
	}
}
@media screen and (min-width: 1px) and (max-width: 767px) {
	.section-graphic-text.the-community::before,
	.section-graphic-text.the-detail::before {
		bottom: 42px;
		left: 36px;
	}
	.section-graphic-text.the-community::before {
		bottom: 48px;
		left: 43px;
	}
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
	.section-graphic-text.the-community::before,
	.section-graphic-text.the-detail::before {
		left: 0px;
	}
	.section-graphic-text.the-detail::before {
		left: 70px;
	}
}

.content-bg {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: -1;
	background-color: #8ad5a7;
}

.contact-logo-wrapper {
	position: absolute;
	left: 0px;
	top: 48px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
@media screen and (min-width: 1px) and (max-width: 1100px) {
	.contact-logo-wrapper {
		transform: rotate(-90deg) translateY(-29vw);
		position: fixed;
		top: 194px;
		height: 60px;
		width: auto;
		display: block;
	}
}

.contact-logo {
	width: 300px;
	height: 32px;
	background-image: url("../images/usp-logo.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.contact-names-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
}

.contact-name {
	width: 40%;
	font-size: 20px;
	line-height: 26px;
}
@media (min-width:1200px) and (max-width:1439px) and (min-height:400px) and (max-height:700px) {
	.contact-name {
		font-size: 14px;
		line-height: 20px;
	}
}
@media (min-width:1440px) and (max-width:1599px) {
	.contact-name {
		font-size: 16px;
		line-height: 24px;
	}
}
.Mr0 {margin-right:0!important;}

.contact-email-link {
	color: #003e34;
}

.contact-logo-misrep {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	/* margin-top: 50px; */
	margin-top: 28px;
}
@media screen and (min-width: 1440px) {
	.contact-logo-misrep {
		margin-top: 50px;
	}
}
@media screen and (min-width: 1900px) {
	.contact-logo-misrep {
		margin-top: 90px;
	}
}

.knightfrank-logo {
	width: 150px;
	/* height: 56px; */
	height: 41px;
	margin-right: 60px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-image: url("../images/knightfrank-logo.svg");
	background-position: 0px top;
	background-size: contain;
	background-repeat: no-repeat;
}

.cushmanwakefield-logo {
	width: 202px;
	height: 41px;
	margin-right: 60px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-image: url("../images/cushmanwakefield-logo.svg");
	background-position: -8px center;
	background-size: cover;
	background-repeat: no-repeat;
}

.misrep {
	max-width: 780px;
	font-size: 12px;
	line-height: 18px;
}

.contact-downloads {
	display: none;
	padding-top: 60px;
	padding-bottom: 50px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-top: 1px solid #003e34;
}

.category-heading {
	font-size: 20px;
	font-weight: 500;
}

.category-heading.contact-heading {
	width: 100%;
	margin-bottom: 35px;
}

.download-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 110px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.download-item {
	width: 50%;
	padding-top: 0px;
	padding-bottom: 15px;
}

.download-text {
	font-size: 17px;
}

.download-sub {
	font-size: 12px;
}

.intro-bg {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: -1;
	background-image: url("../images/intro-bg.jpg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.intro-logo {
	width: 400px;
	height: 49px;
	background-image: url("../images/logo-white.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.loader {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	opacity: 1;
}

.loader-bg {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	background-color: #8ad5a7;
}

.loader-container {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.logo-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 470px;
	height: 100px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.burger-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	margin-left: 70px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.burger-item {
	margin-bottom: 40px;
	font-size: 16px;
	cursor: pointer;
}
@media screen and (min-width: 1px) and (max-width: 767px) {
	.burger-item {
		margin-bottom: 30px;
	}
}

.burger-item.burger-item-last {
	margin-bottom: 0px;
}

.burger-item.feeling-text {
	color: #6ea9dc;
}

.burger-item.feeling-text:hover {
	text-decoration: underline;
}

.loading-mask {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	background-image: url("../images/loading-logo.svg");
	background-position: 0px 0px;
	background-size: contain;
	background-repeat: no-repeat;
}

.loading-progress-wrapper {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	padding: 15px 30px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.loading-progress {
	width: 100%;
	height: 100%;
	background-color: #003e34;
}

.bold {
	font-weight: 700;
}

.image-thumbs-wrapper {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
}

.img-thumb-small {
	position: absolute;
	width: 120px;
	height: 108px;
}

.img-thumb-small.img-thumb-1 {
	left: 30%;
	top: 20%;
	background-image: url("../images/img-thumb-1.png");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.img-thumb-large {
	position: absolute;
	width: 149px;
	height: 135px;
	background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png");
	background-position: 50% 50%;
	background-size: 125px;
	background-repeat: no-repeat;
}

.img-thumb-large.img-thumb-2 {
	left: 20%;
	top: 60%;
	background-image: url("../images/img-thumb-2.png");
	background-size: contain;
}

.img-thumb-large.img-thumb-3 {
	top: 54%;
	right: 10%;
	background-image: url("../images/img-thumb-3.png");
	background-size: contain;
}

.img-thumb-large.img-thumb-4 {
	top: 10%;
	right: 5%;
	background-image: url("../images/img-thumb-4.png");
	background-size: contain;
}

.instructions-wrapper {
	position: fixed;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}
@media screen and (min-width: 1px) and (max-width: 1100px) {
	.instructions-wrapper {
		top: calc(50vh - 50px);
		bottom: auto;
	}
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
	.instructions-wrapper {
		top: calc(60vh - 50px);
	}
}

.instructions-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	max-width: 500px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
@media screen and (min-width: 1px) and (max-width: 1100px) {
	.instructions-container {
		flex-direction: row;
		align-content: space-between;
		justify-content: space-between;
		min-width: 100vw;
		padding-left: 15px;
		/* padding-right: 20px; */
	}
}

.intructions-list {
	width: auto;
	margin: 0;
}
@media screen and (min-width: 1101px) {
	.intructions-list {
		margin-bottom: 10px;
	}
}
.instruction-reset-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 30px;
	padding: 18px 14px 18px 35px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	background-image: url("../images/reset-view-icon.svg");
	background-position: 10px 50%;
	background-repeat: no-repeat;
	font-size: 12px;
	font-weight: 500;
	cursor: pointer;
}
@media screen and (min-width: 1px) and (max-width: 1100px) {
	.instruction-reset-button {
		margin-left: auto;
		height: 25.5px;
	}
}

.instruction-icon {
	margin-right: 7px;
}

.instruction-divider {
	width: 1px;
	height: 12px;
	background-color: #bfcfcc;
}

.instruction-text {
	margin-left: 8px;
	font-size: 10px;
	font-weight: 700;
}

.floor-selector-arrow {
	width: 40px;
	height: 40px;
	cursor: pointer;
}

.floor-selector-arrow.arrow-up {
	margin-bottom: 20px;
	background-image: url("../images/arrow-up.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.floor-selector-arrow.arrow-up:hover {
	background-image: url("../images/arrow-up-hover.svg");
}

.floor-selector-arrow.arrow-down {
	margin-top: 20px;
	background-image: url("../images/arrow-down.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.floor-selector-arrow.arrow-down:hover {
	background-image: url("../images/arrow-down-hover.svg");
}

.floor-view-title {
	font-size: 28px;
	font-weight: 400;
}

.floor-view-title.floor-plan-title {
	margin-top: 7px;
}

.schedule-list {
	margin-top: 20px;
	padding-left: 0px;
	border-top: 1px none #003e34;
	font-size: 16px;
}

.schedule-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-top: 1px solid #003e34;
	cursor: pointer;
}

.schedule-item:hover {
	color: #e18e75;
}

.schedule-title {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.schedule-area {
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	text-align: right;
}

.schedule-area.sqm {
	margin-left: 5px;
	font-size: 11px;
}

.return-button {
	padding-top: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	text-decoration: underline;
	cursor: pointer;
}

.return-button:hover {
	color: #e18e75;
}

.content-overlay {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 1400;
}

.content-overlay-bg {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	background-color: #fff;
	opacity: 0.95;
}

.content-overlay-container {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.content-overlay-container.community-bg {
	background-image: url("../images/community-background.svg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: repeat;
}

.content-overlay-main {
	width: 100%;
}

.plan-view-download {
	margin-top: 30px;
	text-decoration: underline;
	cursor: pointer;
}

.plan-view-download:hover {
	color: #e18e75;
}

.plan-view-elevation {
	width: 80px;
	height: 60px;
}

.plan-view-elevation.elevation-second {
	background-image: url("../images/elevation-second.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.plan-viewer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.plan-image {
	width: 492px;
	height: 492px;
	background-image: url("../images/plan-placeholder.png");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.overlay-close-icon {
	position: fixed;
	left: auto;
	top: 44px;
	right: 50px;
	bottom: auto;
	z-index: 1200;
	width: 40px;
	height: 40px;
	background-image: url("../images/close-icon_1.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
}

.overlay-close-icon:hover {
	background-image: url("../images/close-icon-hover.svg");
}

.overlay-close-icon.close-button-bg {
	background-color: #edf0ef;
}

.plan-direction {
	position: fixed;
	left: auto;
	top: 110px;
	right: 60px;
	bottom: auto;
	width: 16px;
	height: 14px;
	background-image: url("../images/plan-direction.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.plan-controls {
	position: fixed;
	left: auto;
	top: auto;
	right: 55px;
	bottom: 45px;
}

.control-button {
	width: 40px;
	height: 40px;
	margin-bottom: 2px;
	background-color: #edf0ef;
	cursor: pointer;
}

.control-button.zoom-in {
	background-image: url("../images/zoom-in-icon.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.control-button.zoom-in:hover {
	background-color: #e18e75;
	background-image: url("../images/zoom-in-icon-hover.svg");
}

.control-button.zoom-out {
	background-image: url("../images/zoom-out-icon.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.control-button.zoom-out:hover {
	background-color: #e18e75;
	background-image: url("../images/zoom-out-icon-hover.svg");
}

.control-button.zoom-reset {
	background-image: url("../images/zoom-reset-icon.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.control-button.zoom-reset:hover {
	background-color: #e18e75;
	background-image: url("../images/zoom-reset-icon-hover.svg");
}

.gallery {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	padding: 20px;
}

.gallery-image {
	width: 100%;
	height: 100%;
	background-image: url("../images/intro-bg.jpg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.gallery-controls {
	position: absolute;
	left: auto;
	top: auto;
	right: 40px;
	bottom: 40px;
	z-index: 1400;
}

.gallery-title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 500px;
	min-height: 55px;
	min-width: 150px;
	padding: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: #edf0ef;
	font-size: 24px;
	line-height: 26px;
	text-align: right;
}

.gallery-control-buttons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 3px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.gallery-control {
	width: 55px;
	height: 55px;
	margin-left: 3px;
	background-color: #edf0ef;
	cursor: pointer;
}

.gallery-control:hover {
	background-color: #e18e75;
}

.gallery-control.prev-button {
	background-image: url("../images/prev-arrow-icon.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.gallery-control.next-button {
	background-image: url("../images/next-arrow-icon.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.sub-link {
	font-size: 16px;
	line-height: 18px;
	text-decoration: none;
	cursor: pointer;
}

.sub-link:hover {
	color: #e18e75;
}

.download-size {
	font-size: 10px;
	text-decoration: none;
}

.underline {
	text-decoration: underline;
}

.content-page {
	overflow: scroll;
	width: 100%;
	height: auto;
	padding-right: 105px;
	padding-bottom: 100px;
}

.content-page.align-with-header {
	padding-top: 80px;
}

.spec-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.spec-item {
	width: 45%;
	margin-right: 15px;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-top: 1px solid #8ad5a7;
	background-color: #edf0ef;
}

.spec-icon {
	display: none;
	margin-bottom: 15px;
	padding-bottom: 10px;
	border-bottom: 1px solid #003e34;
}

.easit-hero-img {
	width: 100%;
	height: 400px;
	margin-bottom: 40px;
	background-image: url("../images/easit-image-placeholder.jpg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.community-sub-title {
	color: #e18e75;
	font-weight: 500;
}

.easit-benefits-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	padding-left: 0px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.easit-benefits-item {
	width: 45%;
	max-width: 400px;
	margin-right: 25px;
	margin-bottom: 30px;
	padding-right: 20px;
}

.easit-icon {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #003e34;
}

.intro-para {
	max-width: 700px;
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 22px;
}

.intro-para.bottom-margin {
	margin-bottom: 40px;
}

.copy-sub {
	font-size: 10px;
}

.popup-overlay-wrapper {
	position: fixed;
	left: auto;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 350px;
	height: 100%;
	padding-right: 35px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.popup-overlay {
	position: relative;
	width: 100%;
	border-top: 1px solid #e18e75;
	background-color: #fff;
}

.popup-overlay.green-overlay {
	border-top-color: #003e34;
}

.popup-overlay-image {
	position: relative;
	height: 210px;
}

.popup-overlay-image.easit-popup-img {
	background-image: url("../images/easit-image-placeholder.jpg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.popup-overlay-image.bus-popup-img {
	background-image: url("../images/bus-img-placeholder.jpg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.popup-overlay-image.borrowbikes-popup-img {
	background-image: url("../images/borrow-bikes-popup-img.jpg");
	background-position: 0px 0px;
	background-size: cover;
	background-repeat: no-repeat;
}

.popup-overlay-container {
	padding: 20px 20px 22px;
}

.popup-overlay-container.popup-amenity-bg {
	position: relative;
	background-image: url("../images/community-background.svg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.popup-title {
	margin-top: 0px;
	margin-bottom: 18px;
	color: #e18e75;
	font-weight: 500;
	text-decoration: underline;
}

.popup-title.event-title {
	color: #003e34;
}

.text-national-rail-icon {
	position: relative;
	width: 20px;
	height: 12px;
	margin-right: 3px;
	background-image: url("../images/national-rail-green.png");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.copy-internal-link {
	text-decoration: underline;
	cursor: pointer;
}

.copy-internal-link:hover {
	color: #e18e75;
}

.popup-close {
	position: absolute;
	left: auto;
	top: 0px;
	right: 0%;
	bottom: auto;
	width: 40px;
	height: 40px;
	background-color: #e18e75;
	background-image: url("../images/popup-close-icon.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
}

.popup-close:hover {
	background-color: #003e34;
}

.popup-close.green-close {
	background-color: #003e34;
}

.bus-popup-schedule-list {
	margin-bottom: 0px;
}

.bus-popup-schedule-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 8px;
}

.bus-popup-schedule-icon {
	width: 20px;
	height: 20px;
	margin-right: 5px;
}

.bus-popup-schedule-icon.national-rail {
	background-image: url("../images/national-rail-icon.svg");
	background-position: 0px 2px;
	background-repeat: no-repeat;
}

.bus-popup-schedule-icon.tube-icon {
	background-image: url("../images/tube-icon.svg");
	background-position: 0px 2px;
	background-repeat: no-repeat;
}

.bus-popup-schedule-icon.heathrow-icon {
	background-image: url("../images/plane-icon.svg");
	background-position: 0px 2px;
	background-repeat: no-repeat;
}

.bus-popup-schedule-text {
	font-size: 12px;
	line-height: 16px;
}

.connection-category {
	overflow: hidden;
	width: 100%;
	margin-top: 25px;
}

.connection-category-title {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	font-size: 18px;
	font-weight: 500;
}

.connection-category-list {
	margin-top: 8px;
}

.connection-category-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 10px;
	padding-top: 8px;
	padding-bottom: 8px;
	border-top: 1px solid #003e34;
}

.category-icon {
	width: 30px;
}

.category-icon.rail-icon {
	background-image: url("../images/connections-rail-icon.svg");
	background-position: 0px 3px;
	background-repeat: no-repeat;
}

.category-icon.tube-icon {
	background-image: url("../images/connections-tube-icon.svg");
	background-position: 0px 3px;
	background-repeat: no-repeat;
}

.category-icon.plane-icon {
	background-image: url("../images/connections-plane-icon.svg");
	background-position: 0px 2px;
	background-repeat: no-repeat;
}

.category-text {
	line-height: 18px;
}

.virtual-view {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	padding: 20px 0px;
}

.virtual-instruction-wrapper {
	position: absolute;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.virtual-instruction {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 290px;
	height: 75px;
	padding-bottom: 15px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
}

.occupiers-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.occupiers-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: 200px;
	margin-top: -1px;
	margin-right: -1px;
	margin-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	border: 1px solid #edf0ef;
	background-color: #fff;
}

.occupier-logo-area {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png");
	background-position: 50% 50%;
	background-size: 125px;
	background-repeat: no-repeat;
}

.occupier-logo-area.logo-alexion {
	background-image: url("../images/logo-alexion.svg");
}

.occupier-logo-area.logo-apple {
	background-image: url("../images/logo-apple.png");
	background-size: 45px;
}

.occupier-logo-area.logo-canon {
	background-image: url("../images/logo-canon.svg");
	background-size: 103px;
}

.occupier-logo-area.logo-cargologic {
	background-image: url("../images/logo-cargologicmanagement.png");
	background-size: 173px;
}

.occupier-logo-area.logo-celgene {
	background-image: url("../images/logo-celgene.svg");
	background-size: 95px;
}

.occupier-logo-area.logo-coats {
	background-image: url("../images/logo-coats.png");
	background-size: 74px;
}

.occupier-logo-area.logo-gilead {
	background-image: url("../images/logo-gilead.svg");
}

.occupier-logo-area.logo-hasbro {
	background-image: url("../images/logo-hasbro.svg");
	background-size: 98px;
}

.occupier-logo-area.logo-havi {
	background-image: url("../images/logo-havi.svg");
	background-size: 100px;
}

.occupier-logo-area.logo-hikvision {
	background-image: url("../images/logo-hikvision.svg");
}

.occupier-logo-area.logo-img {
	background-image: url("../images/logo-img.svg");
	background-size: 86px;
}

.occupier-logo-area.logo-stryker {
	background-image: url("../images/logo-stryker.svg");
	background-size: 102px;
}

.occupier-logo-area.logo-kuehne {
	background-image: url("../images/logo-kuehne.svg");
	background-size: 150px;
}

.occupier-logo-area.logo-lucozade {
	background-image: url("../images/logo-lucozade.png");
}

.occupier-logo-area.logo-ms {
	background-image: url("../images/logo-ms.svg");
	background-size: 110px;
}

.occupier-logo-area.logo-mitsubishi {
	background-image: url("../images/logo-mitsubishi.svg");
	background-size: 139px;
}

.occupier-logo-area.logo-msc {
	background-image: url("../images/logo-msc.svg");
}

.occupier-logo-area.logo-nobel {
	background-image: url("../images/logo-nobel.png");
}

.occupier-logo-area.logo-orega {
	background-image: url("../images/logo-orega.png");
	background-size: 108px;
}

.occupier-logo-area.logo-regus {
	background-image: url("../images/logo-regus.svg");
	background-size: 88px;
}

.occupier-logo-area.logo-samsonite {
	background-image: url("../images/logo-samsonite.png");
	background-size: 127px;
}

.occupier-logo-area.logo-sharp {
	background-image: url("../images/logo-sharp.svg");
	background-size: 100px;
}

.occupier-logo-area.logo-spaces {
	background-image: url("../images/logo-spaces.svg");
	background-size: 110px;
}

.occupier-logo-area.logo-toshiba {
	background-image: url("../images/logo-toshiba.svg");
	background-size: 110px;
}

.occupier-logo-area.logo-verifone {
	background-image: url("../images/logo-verifone.svg");
	background-size: 110px;
}

.occupier-logo-area.logo-worldvision {
	background-image: url("../images/logo-worldvision.svg");
}

.occupier-logo-area.logo-fiserv {
	background-image: url("../images/logo-fiserv.svg");
	background-size: 72px;
}

.occupier-text-area {
	padding: 12px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.occupier-address {
	padding-left: 12px;
	background-image: url("../images/address-pin-icon.svg");
	background-position: 0% 50%;
	background-repeat: no-repeat;
	font-size: 11px;
}

.gallery-thumb-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 10px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
}

.thumb {
	width: 20px;
	height: 2px;
	margin-right: 8px;
	background-color: #edf0ef;
	opacity: 0.4;
	color: #edf0ef;
}

.thumb.thumb-active {
	opacity: 1;
}

.schedule-diagram {
	height: 200px;
}

.schedule-sub-list {
	width: 100%;
	margin-top: 8px;
	padding-left: 15px;
}

.schedule-sub-list.ground-stats-list {
	margin-top: 25px;
	padding-top: 5px;
	padding-left: 0px;
	border-top: 1px solid #003e34;
}

.schedule-sub-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 5px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-size: 14px;
}

.schedule-sub-text.sqm {
	margin-left: 5px;
	font-size: 11px;
}

.schedule-sub-text.sub-label {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.notice {
	font-size: 11px;
	line-height: 14px;
}

.arena-thumb {
	width: 100%;
	height: 200px;
	margin-top: 35px;
	margin-bottom: 20px;
	background-image: url("../images/arena-placeholder-img.jpg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.amenity-list-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 30px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.amenity-list-wrapper {
	margin-right: 15px;
	margin-bottom: 20px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.amenity-list-wrapper.full-width {
	width: 100%;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.amenity-icon {
	width: 20px;
	height: 20px;
}

.amenity-icon.amenity-food-icon {
	background-image: url("../images/amenity-food-icon.svg");
	background-position: 0% 50%;
	background-repeat: no-repeat;
}

.amenity-icon.amenity-fitness-icon {
	background-image: url("../images/amenity-fitness-icon.svg");
	background-position: 0% 50%;
	background-repeat: no-repeat;
}

.amenity-icon.amenity-shop-icon {
	background-image: url("../images/amenity-shop-icon.svg");
	background-position: 0% 50%;
	background-repeat: no-repeat;
}

.amenity-list {
	margin-top: 5px;
	padding-top: 10px;
	border-top: 1px solid #003e34;
}

.amenity-list-item {
	font-size: 12px;
	font-weight: 500;
}

.connection-category-title-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
}

.connection-category-title-wrapper:hover {
	color: #e18e75;
}

.expand-icon {
	width: 15px;
	height: 15px;
	background-image: url("../images/expand-icon.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.contact-development-line {
	width: 100%;
	/* margin-top: 50px; */
	/* padding-top: 30px; */
	margin-top: 20px;
	padding-top: 22px;
	border-top: 1px solid #003e34;
}

.contact-development-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.marick-logo {
	width: 95px;
	height: 17px;
	margin-right: 15px;
	margin-bottom: 5px;
	margin-left: 8px;
	background-image: url("../images/marick-logo.svg");
	background-position: 0px 0px;
	background-size: contain;
	background-repeat: no-repeat;
}

.vert-divider {
	width: 1px;
	height: 23px;
	background-color: #003e34;
}

.tritax-logo {
	width: 87px;
	height: 16px;
	margin-bottom: 5px;
	margin-left: 15px;
	background-image: url("../images/tritax-logo.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.virtual-viewer-nav {
	position: absolute;
	left: 15px;
	top: auto;
	right: auto;
	bottom: 50px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.virtual-compass {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	height: 40px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 3px;
	background-color: #003e34;
	cursor: pointer;
}

.virtual-compass:hover {
	background-color: #fff;
}

.virtual-compass-icon {
	width: 26px;
	height: 26px;
	background-image: url("../images/virtual-compass.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.virtual-plan-wrapper {
	position: relative;
	width: 220px;
	height: 220px;
	margin-bottom: 10px;
	border-radius: 3px;
	background-color: #fff;
}

.virtual-icon {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 24px;
	height: 24px;
	margin-top: -12px;
	margin-left: -12px;
	background-image: url("../images/viewer-nav-hotspot.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
}

.virtual-icon:hover {
	background-image: url("../images/viewer-nav-hover.svg");
}

.virtual-icon.virtual-icon-active {
	background-image: url("../images/viewer-nav-hotspot-active.svg");
}

.popup-event-banner {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

.popup-event-icon {
	width: 38px;
	height: 38px;
	background-color: #e18e75;
}

.popup-event-text {
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 20px;
	background-color: #e18e75;
	font-size: 12px;
	font-weight: 500;
}

.burger-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 340px;
	height: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.burger-group.community-text {
	color: #e18e75;
}

.burger-group-item {
	margin-bottom: 8px;
	cursor: pointer;
}

.burger-group-item:hover {
	text-decoration: underline;
}

.burger-group-item.sub-burger-item {
	padding-left: 20px;
}

.ribbon-nav {
	position: fixed;
	left: 70px;
	top: 43px;
	right: auto;
	bottom: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 21px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	z-index: 1000;
	opacity: 0;
}
@media screen and (min-width: 1px) and (max-width: 1101px) {
	.ribbon-nav {
		opacity: 0 !important;
	}
}
@media screen and (min-width: 1101px) {
	.ribbon-nav {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		display: flex;
		/* flex-direction: column-reverse; */
		flex-direction: column;
		align-content: space-around;
		height: 100%;
		width: 65px;
		padding: 98px 0 33px;
		background: #fff;
	}
	.ribbon-nav::before {
		content: "";
		display: block;
		position: absolute;
		left: 32px;
		top: 181px;
		bottom: 116px;
		width: 1px;
		background: #bfcfcc;
	}
}

.ribbon-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25px;
	height: 100%;
	padding-right: 5px;
	padding-left: 5px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	font-size: 10px;
	font-weight: 500;
	opacity: 0.2;
	cursor: pointer;
	transition: all 0.015s ease !important;
}
@media screen and (min-width: 1101px) {
	.ribbon-item {
		width: auto;
		width: auto !important;
		height: auto !important;
		margin: auto 0;
		font-size: 11.2px;
		padding-right: 15px;
		padding-left: 15px;
		color: #a6c0bc;
		background: #ffffff;
		opacity: 1;
		-webkit-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg);
	}
}

.ribbon-item:hover {
	font-size: 16px;
	color: #003e34;
	opacity: 1;
}

.ribbon-item.ribbon-item-expanded {
	width: auto;
	height: 100%;
	color: #003e34;
	opacity: 1;
}
.ribbon-item.ribbon-item-expanded.location-ribbon,
.ribbon-item.ribbon-item-expanded.connections-ribbon {
	color: #e18e75;
}
@media screen and (min-width: 1101px) {
	.ribbon-item.ribbon-item-expanded {
		font-size: 16px;
	}
}

.ribbon-line {
	overflow: hidden;
	width: 100%;
	height: 2px;
	background-color: #003e34;
	transition: all 0.1s linear;
}
@media screen and (min-width: 1101px) {
	.ribbon-line {
		height: auto;
		background: transparent;
		white-space: nowrap;
	}
}

.ribbon-line.ribbon-line-expanded {
	height: 100%;
	background-color: transparent;
}

.spec-text {
	margin-bottom: 5px;
}

.spec-text.bold {
	font-weight: 700;
}

.sub {
	font-size: 10px;
}

.usp-address {
	margin-top: 15px;
	font-size: 11px;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.2px;
}

.usp-logo-wrapper {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: none;
	width: 100%;
	height: 63px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

@media screen and (min-width: 1101px) {
	.usp-logo-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}
}

.usp-logo {
	width: 180px;
	height: 40px;
	background-image: url("../images/usp-logo.svg");
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

@media screen and (max-width: 991px) {
	.burger-button-wrapper {
		left: 30px;
		top: 30px;
		right: 30px;
	}
}

@media screen and (min-width: 1px) and (max-width: 767px) {
	.gallery-content {
		width: 100%;
		height: 100%;
	}
	.burger-button-wrapper {
		/* left: 20px; */
		left: 0;
		top: 15px;
		right: 20px;
	}
}

@media screen and (max-width: 1100px) {
	.burger-button-wrapper {
		top: 0;
		left: 0;
	}
	.intro-logo {
		max-width: calc(100vw - 40px);
	}
}

@media screen and (min-width: 1100px) {
	.contact-downloads-container {
		min-height: 354px;
	}
}

@font-face {
	font-family: "Favorit Trial";
	src: url("../fonts/FavoritTrialStd-Italic.woff2") format("woff2"),
		url("../fonts/FavoritTrialStd-BookItalic.woff2") format("woff2"),
		url("../fonts/FavoritTrialStd-BookItalic.woff") format("woff"),
		url("../fonts/FavoritTrialStd-Italic.woff") format("woff");
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: "Favorit Trial";
	src: url("../fonts/FavoritTrialStd-MediumItalic.woff2") format("woff2"),
		url("../fonts/FavoritTrialStd-MediumItalic.woff") format("woff");
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: "Favorit Trial";
	src: url("../fonts/FavoritTrialStd-LightItalic.woff2") format("woff2"),
		url("../fonts/FavoritTrialStd-LightItalic.woff") format("woff");
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: "Favorit Trial";
	src: url("../fonts/FavoritTrialStd-BoldItalic.woff2") format("woff2"),
		url("../fonts/FavoritTrialStd-BoldItalic.woff") format("woff");
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: "Favorit Trial";
	src: url("../fonts/FavoritTrialStd-Bold.woff2") format("woff2"),
		url("../fonts/FavoritTrialStd-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "Favorit Trial";
	src: url("../fonts/FavoritTrialStd-Medium.woff2") format("woff2"),
		url("../fonts/FavoritTrialStd-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "Favorit Trial";
	src: url("../fonts/FavoritTrialStd-Book.woff2") format("woff2"),
		url("../fonts/FavoritTrialStd-Regular.woff2") format("woff2"),
		url("../fonts/FavoritTrialStd-Book.woff") format("woff"),
		url("../fonts/FavoritTrialStd-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Favorit Trial";
	src: url("../fonts/FavoritTrialStd-Light.woff2") format("woff2"),
		url("../fonts/FavoritTrialStd-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}
