a {
  text-decoration: inherit;
  color: inherit;
}

.no-event {
  pointer-events: none;
}

.clickable {
  pointer-events: all;
}

.hidden {
  display: none !important;
}

.debug {
  position: relative;
  z-index: 1100;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.fade,
.auto-fade,
.fade-out {
  opacity: 0;
}

.anim-all-200 {
  transition: all ease-out 200ms;
}

.anim-all-400 {
  transition: all ease-out 400ms;
}

.anim-slide-up {
  transform: translateY(20px);
}

.debug-stats-panel {
  position: fixed;
  left: 45px;
  top: 45px;
}

.state-label {
  cursor: pointer;
}

.state-selector-list {
  display: none;
}

.state-label-wrapper:hover .state-selector-list {
  display: block;
}

.content-fade {
  pointer-events: none;
  background: rgb(236, 240, 239);
  background: linear-gradient(
    90deg,
    rgba(236, 240, 239, 1) 45%,
    rgba(236, 240, 239, 0) 70%
  );
}

.content-top-fade {
  pointer-events: none;
  background: rgb(236, 240, 239);
  background: linear-gradient(
    180deg,
    rgba(236, 240, 239, 0.7) 0%,
    rgba(236, 240, 239, 0) 100%
  );
}

.content-bottom-fade {
  pointer-events: none;
  background: rgb(236, 240, 239);
  background: linear-gradient(
    0deg,
    rgba(236, 240, 239, 0.7) 0%,
    rgba(236, 240, 239, 0) 100%
  );
}

.content-right-fade {
  pointer-events: none;
  background: rgb(236, 240, 239);
  background: linear-gradient(
    270deg,
    rgba(236, 240, 239, 0.5) 0%,
    rgba(236, 240, 239, 0) 100%
  );
}

.burger-button-wrapper {
  pointer-events: none;
  z-index: 2100;
}

.burger-overlay {
  transform: translateX(-100%);
  /* opacity: 0; */
  transition: all 300ms ease-in-out;
  pointer-events: none;
}

.burger-overlay.burger-overlay-show {
  transform: translateX(0%);
  /* opacity: 1; */
  pointer-events: all;
}

.burger-item {
  opacity: 0;
  pointer-events: none;
  transition: all 200ms ease-in-out;
  transform: translateX(-20px);
}

.burger-item.burger-item-show {
  opacity: 1;
  pointer-events: all;
  transform: translateX(0px);
}

.ribbon-nav {
  opacity: 0;
  transition: all 400ms 1s ease-in-out;
  transform: translateX(-10px);
  pointer-events: none;
}

.ribbon-nav.ribbon-nav-show {
  opacity: 1;
  transform: translateX(0px);
  pointer-events: all;
}

.ribbon-item {
  transition: all 200ms ease-in-out;
}

.virtual-instruction {
  padding-top: 20px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 100%
  );
}

.compass svg {
  overflow: visible !important;
}

.compass {
  width: 36px;
  height: 36px;
}

.loading-progress {
  width: 0%;
  transition: all 400ms ease-in-out;
}

.loading-mask {
  background-image: none;
}

.copy {
  font-size: 16px;
  line-height: 21px;
}

.content-main {
  max-width: 390px;
}

/* .logo-container {
	opacity: 0;
} */

.burger-item {
  opacity: 0;
}

.img-thumb {
  opacity: 0;
}

.disable-fade {
  opacity: 0.2;
  pointer-events: none;
}

.plan-viewer-info {
  z-index: 1600;
}

.plan-image {
  background-image: none;
  position: relative;
  cursor: grab;
  width: 70%;
  height: 50vw;
  margin-left: -25%;
}

.plan-view-elevation {
  background-repeat: no-repeat;
}

.easit-icon {
  background-repeat: no-repeat;
}

.easit-bike-icon {
  width: 34px;
  height: 30px;
  background-image: url("../images/easit-bike-icon.svg");
}

.easit-bus-icon {
  width: 25px;
  height: 35px;
  background-image: url("../images/easit-bus-icon.svg");
}

.easit-people-icon {
  width: 27px;
  height: 29px;
  background-image: url("../images/easit-people-icon.svg");
}

.easit-moped-icon {
  width: 19px;
  height: 35px;
  background-image: url("../images/easit-moped-icon.svg");
}

.easit-electric-icon {
  width: 26px;
  height: 35px;
  background-image: url("../images/easit-electric-icon.svg");
}

.easit-car-icon {
  width: 33px;
  height: 26px;
  background-image: url("../images/easit-car-icon.svg");
}

.easit-leaf-icon {
  width: 32px;
  height: 30px;
  background-image: url("../images/easit-leaf-icon.svg");
}

.easit-parking-icon {
  width: 27px;
  height: 37px;
  background-image: url("../images/easit-parking-icon.svg");
}

.easit-discount-icon {
  width: 26px;
  height: 35px;
  background-image: url("../images/easit-discount-icon.svg");
}

.spec-icon {
  width: 34px;
  height: 48px;
  background-repeat: no-repeat;
  background-image: url("../images/spec-icon-placeholder.svg");
}

.spec-list {
  margin-top: 40px;
}

.community-bg {
  background-image: url("../images/community-background.svg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: repeat;
}
.gallery-image {
  background-image: none;
  transition: opacity 200ms ease-in-out;
  background-color: white;
  background-size: contain;
}
.occupier-popup-item {
  width: 50%;
  height: 120px;
  padding-left: 15px;
  padding-right: 15px;
}

.occupier-popup-item .occupier-logo-area {
  background-size: contain !important;
}

.popup-overlay-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.text-tube-icon {
  background-image: url("../images/green-tube-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.schedule-ground-img {
  background-image: url("../images/elevation-ground.svg");
  background-size: 138px 96px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.schedule-first-img {
  background-image: url("../images/elevation-first.svg");
  background-size: 138px 96px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.schedule-second-img {
  background-image: url("../images/elevation-second.svg");
  background-size: 138px 96px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.schedule-all-img {
  background-image: url("../images/elevation-all.svg");
  background-size: 138px 96px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.connection-category {
  transition: all 400ms ease-in-out;
}

.expand-tab-closed {
  height: 40px !important;
  transition: all 400ms ease-in-out;
}

.expand-icon {
  transition: all 100ms ease-in-out;
}

.expand-icon-open {
  transform: rotate(90deg);
}

.instruction,
.instruction-reset-button {
  transition: all 200ms ease-in-out;
}

.instruction-hide {
  opacity: 0;
  transform: translateY(10px);
}

.custom-hotspot,
.custom-hotspot-down {
  background-image: url("../images/hotspot-up.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 37px;
  height: 37px;
}

.custom-hotspot:hover {
  background-image: url("../images/hotspot-up-hover.png");
}

.custom-hotspot-down {
  background-image: url("../images/hotspot-down.png");
}

.custom-hotspot-down:hover {
  background-image: url("../images/hotspot-down-hover.png");
}

.custom-tooltip {
  color: #003e34 !important;
  font-family: "Favorit Trial", sans-serif;
  max-width: 600px !important;
  word-break: keep-all;
}

.custom-tooltip .custom-label {
  visibility: hidden;
  position: absolute;
  cursor: default;
  text-align: center;
  word-break: keep-all;
  width: 300px;
  display: flex;
  justify-content: center;
}

.custom-label p {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 2px;
  margin: 0px;
  background-color: white;
  width: auto;
  position: relative;
}

.custom-tooltip:hover .custom-label {
  visibility: visible;
}

.virtual-compass-icon {
  pointer-events: none;
}

.virtual-compass {
  cursor: pointer;
  position: relative;
  z-index: 1000;
}

.virtual-plan-wrapper {
  background-size: 180px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.return-button {
  font-size: 18px;
}

.simplebar-padding {
  padding-right: 30px;
  max-width: 300px;
}

.simplebar-scrollbar::before {
  width: 3px !important;
  background-color: #8ad5a7 !important;
  color: #8ad5a7 !important;
}

.simplebar-track {
  /* background-color: #edf0ef !important; */
  /* width: 3px !important; */
  padding-left: 10px;
  padding-top: 40px;
  padding-bottom: 20px;
}

.popup-event-icon {
  background-repeat: no-repeat;
  background-size: 21px 21px;
  background-position: 50% 50%;
}

.fitness-event-icon {
  background-image: url("../images/fitness-popup-icon.svg");
  background-size: 21px 21px;
}

.food-event-icon {
  background-image: url("../images/food-popup-icon.svg");
  background-size: 12px 21px;
}

.star-event-icon {
  background-image: url("../images/star-popup-icon.svg");
  background-size: 16px 15px;
}

.plan-viewer-ground,
.floor-plan-title {
  opacity: 0;
}

.plan-viewer-ground {
  margin-bottom: 300px;
}

.floor-selector,
.schedule-sub-list {
  position: relative;
  z-index: 2000;
}

.extra-wide {
  max-width: 300px;
}

.occupier-text-area {
  display: none;
}

.content-page {
  overflow-y: scroll;
  overflow-x: hidden;
}

.misrep {
  font-size: 10px;
  line-height: 12px;
}

.total-item {
  pointer-events: none;
}
.check-component.plan-view-checks {
  margin-top: 15px;
}

.plan-viewer-detail {
  position: fixed;
  bottom: 0px;
  right: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 220px;
  align-items: flex-end;
}

.plan-view-download {
  margin-top: 10px;
}

.video-player-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #bdd3ef;
  transition: all 400ms ease-in-out;
  z-index: 10000;
}

.video-full {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* pointer-events: none; */
  overflow: hidden;
}

.video-wipe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #8ad5a7;
  transition: all 800ms ease-in-out;
  pointer-events: none;
}

.logo-container {
  transition: all 800ms ease-in-out;
}

.letters-to-fade,
.loader {
  transition: all 400ms ease-in-out;
}

.loader {
  z-index: 10000;
}

.video-full iframe .play {
  display: none;
}

.video-play {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #8ad5a7;
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.video-play-button {
  width: 100px;
  height: 100px;
  background-image: url("../images/play-icon.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.video-play-button:hover {
  background-image: url("../images/play-icon-hover.svg");
}

.video-skip-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 60px;
  height: 30px;
  background-image: url("../images/skip-icon.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2000;
  cursor: pointer;
}

.video-skip-button:hover {
  background-image: url("../images/skip-icon-hover.svg");
}

.amenity-list-container {
  margin-top: 5px;
}

.amenity-list-wrapper {
  margin-bottom: 10px;
}

.arena-thumb {
  height: 170px;
  cursor: pointer;
}

.always-top {
  z-index: 2000;
}

.slide-nav-wrapper.intro-nav-wrapper.intro-nav-wrapper {
  background-image: none !important;
  height: auto !important;
  width: 720px !important;
  flex-direction: column;
  background-color: transparent !important;
  align-items: flex-end;
  right: 20px;
}

.intro-text {
  /* cursor: auto; */
  position: static !important;
  top: 0px;
  left: 0px;
  right: 0px;
  color: white !important;
  font-size: 45px !important;
  line-height: 55px !important;
}

.intro-text .intro-sub {
  background-color: #ffffff !important;
  padding-top: 8px !important;
}

.intro-marker {
  color: #003e34;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.5px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.intro-marker-highlight {
  background-color: #003e34;
  color: white;
}

.slide-intro-text {
  background-color: #8ad5a7;
  padding-left: 20px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 25px;
  margin-top: 40px;
  background-image: url("../images/slide-nav-arrow.svg");
  background-position: 90% 50%;
  background-repeat: no-repeat;
  background-size: 30px 30px;
}

.intro-sub {
  font-size: 14px;
  color: #003e34;
}

.overview-gallery-thumb {
  width: 355px;
  height: 30vh;
  max-height: 240px;
  margin-bottom: 20px;
  background-color: #003e34;
  margin-top: 20px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.overview-gallery-thumb-zoom {
  width: auto;
  height: 28px;
  position: absolute;
  background-image: url("../images/gallery-thumb-zoom-icon.svg");
  background-color: #003e34;
  background-repeat: no-repeat;
  background-position: 5% 49%;
  top: 0px;
  left: 0px;
  border-radius: 6px 0px 3px 0px;
  z-index: 500;
  padding-left: 30px;
  padding-right: 10px;
  color: white;
  font-size: 10px;
  letter-spacing: 0.5px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.overview-gallery-thumb:hover .overview-gallery-thumb-zoom {
  background-color: #8ad5a7;
}

.overview-gallery-thumb-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  transition: opacity 600ms ease-in-out;
}

.contact-content {
  flex-direction: inherit;
  align-items: center;
}

.contact-downloads-container {
  width: 286px;
  margin-left: 30px;
  flex-shrink: 0;
}

.downloads-list {
  margin-top: 20px;
  list-style: none;
  padding: 0px;
}

.downloads-item {
  display: flex;
  border-bottom: 1px solid #e18e75;
  padding-top: 20px;
  padding-bottom: 10px;
}

.no-border {
  border-bottom: none;
}

.downloads-icon {
  width: 16px;
  height: 14px;
  margin-right: 20px;
  margin-top: 6px;
  background-image: url("../images/download-icon.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.downloads-item:hover p {
  color: #e18e75;
}

.contact-name {
  width: auto;
  margin-right: 30px;
}

.inst-left-click {
  width: 15px;
  height: 22px;
  background-image: url("../images/left-click-icon.svg");
  background-position: 50% 0%;
  background-repeat: no-repeat;
}

.inst-right-click {
  width: 15px;
  height: 22px;
  background-image: url("../images/right-click-icon.svg");
  background-position: 50% 0%;
  background-repeat: no-repeat;
}

.inst-middle-scroll {
  width: 12px;
  height: 16px;
  background-image: url("../images/middle-scroll-icon.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
}

.touch-orbit-icon {
  width: 16px;
  height: 20px;
  background-image: url("../images/touch-rotate-icon.svg");
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: contain;
}

.touch-pan-icon {
  width: 15px;
  height: 23px;
  background-image: url("../images/touch-pan-icon.svg");
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: contain;
}

.touch-zoom-icon {
  width: 18px;
  height: 19px;
  background-image: url("../images/touch-zoom-icon.svg");
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: contain;
}

.desktop-nav-graphic {
  position: absolute !important;
  bottom: 0px !important;
  left: 0px !important;
  width: 75px !important;
  z-index: 1000 !important;
}

.mob-clickable {
  pointer-events: none;
}

.schedule-sub-item {
  font-size: 13px;
}

.schedule-area {
  align-self: flex-start;
}

.virtual-plan-note {
  font-size: 10px;
  line-height: 13px;
  font-weight: 400;
  background-color: #003e34;
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
  margin-bottom: 5px;
  margin-top: 0px;
  text-align: left;
  margin-left: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
}

.virtual-compass {
  flex-shrink: 0;
}

.virtual-compass-container {
  display: flex;
}

.intro-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.intro-let {
  position: fixed;
  font-weight: bold;
  font-size: 18px;
  top: 40px;
  right: 40px;
  text-align: right;
  letter-spacing: 0.6px;
  color: #003e34;
  background-color: #e18e75;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-left-width: 4px;
  border-left-style: solid;
  border-color: #003e34;
}

.logo-clm {
  background-image: url("../images/logo-clm.svg");
}

.building-available-note {
  background-color: white;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 10px;
  margin-bottom: 15px;
  border-left-color: #e18e75;
  border-left-style: solid;
  border-left-width: 3px;
}

@media screen and (min-width: 1px) and (max-width: 1100px) {
  .overview-gallery-thumb {
    height: 400px;
    max-height: none;
    width: 100%;
    margin-top: 10px;
  }

  .floor-selector-arrow.arrow-up {
    margin-bottom: 0px;
  }

  .floor-view-title {
    font-size: 22px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .floor-selector-arrow.arrow-down {
    margin-top: 0px;
  }
  .schedule-sub-list.ground-stats-list {
    margin-top: 10px;
    margin-bottom: 20px;
    max-width: 400px;
  }

  .section-nav-item {
    font-size: 20px;
    line-height: 26px;
    padding-top: 0px;
    padding-bottom: 5px;
  }
  .schedule-sub-item {
    font-size: 12px;
    line-height: 12px;
  }
  .plan-viewer {
    position: absolute;
    height: 80vh;
    left: 0px;
    top: 0px;
    right: 0px;
  }
  .plan-view-elevation {
    position: fixed;
    left: 40px;
    bottom: 40px;
  }
  .overlay-close-icon {
    top: 30px;
    right: 30px;
  }

  .plan-direction {
    right: 40px;
  }
  .plan-image {
    margin-left: 0px;
  }
  .plan-viewer-detail {
    display: none;
  }

  .mob-clickable {
    pointer-events: all;
  }

  .popup-overlay-wrapper {
    z-index: 2000;
  }
}

@media screen and (max-width: 767px) {
  .intro-marker {
    text-align: right;
    font-size: 15px;
  }
  .content-main.contact-content {
    padding-left: 80px;
    padding-right: 0px;
  }
  .contact-names-container {
    padding-right: 30px;
  }
  .contact-logo {
    height: 20px;
  }
  .usp-address {
    margin-top: 5px;
  }
  .return-button {
    font-size: 14px;
  }
  .floor-selector-arrow {
    width: 25px;
    height: 25px;
    background-size: contain;
  }
  .section-nav-item {
    font-size: 18px;
    line-height: 24px;
  }
  .content-main__scroll {
    padding-right: 0px;
  }
  .plan-viewer {
    height: 70vh;
  }

  .schedule-sub-list.ground-stats-list {
    margin-top: 10px;
    margin-bottom: 20px;
    max-width: 250px;
  }
  .plan-view-elevation {
    left: 20px;
  }
  .plan-controls {
    left: 40px;
    bottom: 110px;
  }
}

@media screen and (max-width: 1101px) {
  .extra-wide {
    max-width: none;
  }

  .section-graphic {
    height: 75px;
  }

  .contact-logo-wrapper {
    /* position: relative;
		top: 0px; */
  }

  .contact-logo {
    height: 30px;
  }

  .content-main.contact-content {
    /* display: block; */
    position: relative;
    padding-top: 205px;
    padding-left: 200px;
    padding-right: 80px;
    padding-bottom: 200px;
    flex-direction: column;
    justify-content: flex-start;
    overflow: scroll;
    /* height: auto; */
  }

  .contact-names-container {
    display: block !important;
  }

  .contact-name {
    width: auto;
    margin-bottom: 30px;
  }

  .contact-logo-misrep {
    display: block !important;
  }
  .knightfrank-logo {
    margin-bottom: 30px;
  }
  .contact-downloads-container {
    width: 100%;
    margin-left: 0px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .content-main.contact-content {
    padding-left: 80px;
    padding-right: 0px;
  }
  .contact-names-container {
    padding-right: 30px;
  }
  .contact-logo {
    height: 20px;
  }
  .usp-address {
    margin-top: 5px;
  }

  .slide-nav-wrapper.intro-nav-wrapper.intro-nav-wrapper {
    width: 600px !important;
    right: 0px;
  }

  .intro-text {
    background-color: transparent !important;
    padding-right: 0px !important;
    font-size: 35px !important;
    line-height: 45px !important;
    margin-top: 10px;
    padding-top: 0px !important;
  }

  .slide-intro-text {
    margin-top: 20px;
    font-size: 20px;
  }
}

@media screen and (max-width: 568px) {
  .contact-heading,
  .contact-name {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
  }

  .knightfrank-logo {
    width: 105px;
    height: 39px;
    margin-bottom: 30px;
  }
  .marick-logo {
    margin-right: 5px;
  }
  .tritax-logo {
    margin-left: 5px;
  }

  .slide-nav-wrapper.intro-nav-wrapper.intro-nav-wrapper {
    width: 450px !important;
    right: 0px;
    padding-right: 15px;
  }

  .intro-text {
    background-color: transparent !important;
    padding-right: 0px !important;
    font-size: 25px !important;
    line-height: 30px !important;
    margin-top: 10px;
    padding-top: 0px !important;
  }

  .intro-let {
    display: flex;
    width: 100%;
    height: 57px;
    text-align: left;
    left: 58px;
    font-size: 16px;
    top: 0px;
    right: auto;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-right: 100px;
    align-items: center;
  }
  .intro-marker {
    font-size: 12px;
  }

  .intro-text {
    font-size: 29px !important;
    line-height: 34px !important;
  }

  .slide-nav-wrapper.intro-nav-wrapper.intro-nav-wrapper {
    width: 100% !important;
  }
}

@media screen and (max-width: 460px) {
  .intro-text {
    font-size: 26px !important;
    line-height: 33px !important;
  }
}

@media screen and (max-height: 940px) {
  .intro-text {
    font-size: 34px !important;
    line-height: 40px !important;
    margin-top: 10px !important;
  }

  .slide-intro-text {
    margin-top: 10px !important;
  }
}

/* NEW SCROLL BEHAVIOUR */

@media screen and (max-width: 1101px) {
  .render {
    /* background-color: red; */
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
  }

  .interface {
    display: flex;
    position: static;
    /* margin-top: -40vh; */
    padding-top: 65vh;
  }

  .app {
    /* overflow: scroll; */
    position: static;
    display: flex;
    flex-direction: column-reverse;
  }

  .instructions-wrapper {
    position: absolute;
  }

  .slide {
    position: static;
  }

  .content-main {
    padding-top: 0px;
  }
  .content-side {
    position: fixed;
    transition: all 400ms ease-in-out;
  }
  .content-fade {
    position: fixed;
    height: 400px;
  }

  .content-scroll-fade {
    position: fixed;
    pointer-events: none;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #ecf0ef;
    opacity: 0;
    transition: all 400ms ease-in-out;
    z-index: -1;
  }
  .scrolled-fade {
    /* height: 1000px; */
    opacity: 0.9;
  }

  .section-header.fulfillment-at-work {
    top: 64vh;
    height: 140px;
    left: 50px;
  }
  .section-header.in-good-company {
    top: 64vh;
    width: 70px;
    left: 50px;
  }
  .section-header.commute-happy {
    top: 64vh;
    width: 70px;
    left: 50px;
  }
  .section-header.built-for-purpose {
    top: 64vh;
    width: 70px;
    left: 50px;
  }

  .overlays .content-main {
    padding-left: 40px;
    padding-top: 80px;
    min-width: auto;
    width: 300px;
  }
  .overlays .content-page {
    padding-right: 35px;
  }
  .intro-content {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .content-bg {
    position: fixed;
  }

  .contact-slide {
    margin-top: -65vh;
  }
}

@media screen and (max-width: 767px) and (min-height: 641px) {
  .slide {
    width: 100vw;
  }
  .interface {
    padding-top: 63vh;
  }
  .content-main {
    /* padding: 0px; */
    width: 100%;
    max-width: none;
  }
  .simplebar-padding {
    padding-right: 20px;
    max-width: none;
  }
  .instructions-wrapper {
    top: 55vh;
  }
  .content-fade {
    height: 40vh;
  }
  .section-header.fulfillment-at-work {
    top: 64vh;
    height: 100px;
    left: 24px;
  }
  .section-header.in-good-company {
    top: 64vh;
    width: 50px;
    left: 27px;
  }
  .section-header.commute-happy {
    top: 64vh;
    width: 50px;
    left: 27px;
  }
  .section-header.built-for-purpose {
    top: 64vh;
    width: 50px;
    left: 27px;
  }
  .section-graphic-text.the-detail::before {
    font-size: 12px;
    transform: rotate(0deg);
    bottom: 50px;
    left: 20px;
  }
  .section-graphic-text.the-community::before {
    font-size: 12px;
    transform: rotate(0deg);
    bottom: 68px;
    left: 12px;
  }
  .burger-item {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .burger-group-item {
    line-height: 15px;
  }
  .instruction-reset-button {
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 30px;
  }
  .copy {
    margin-bottom: 10px;
  }
  .section-bottom-wrapper {
    margin-top: 20px;
  }
  .specification-overlay .content-main,
  .occupiers-overlay .content-main,
  .easit-overlay .content-main {
    display: none;
  }
  .specification-overlay .content-page,
  .occupiers-overlay .content-page,
  .easit-overlay .content-page {
    padding-left: 20px;
    padding-right: 20px;
  }
  .specification-overlay .spec-item {
    width: 100%;
  }

  .occupiers-overlay .occupiers-item {
    width: 50%;
  }
  .slide-nav-wrapper.intro-nav-wrapper .intro-text {
    right: 20px;
  }
  .slide-nav-wrapper.intro-nav-wrapper {
    right: 20px;
  }
  .logo-container {
    width: 300px;
  }
  .loading-mask svg {
    width: 100%;
  }
  .loading-progress-wrapper {
    padding-top: 21px;
    padding-bottom: 21px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .intro-logo {
    width: 260px;
  }
}

/* LANDSCAPE RESPONSIVENESS */
@media (max-width: 767px) and (max-height: 640px) {
  .intro-text {
    font-size: 20px !important;
    line-height: 24px !important;
    margin-bottom: -15px !important;
    margin-top: 0px;
    padding-bottom: 0px;
  }
  .slide-nav-wrapper.intro-nav-wrapper.intro-nav-wrapper {
    width: 100% !important;
    display: flex !important;
    flex-direction: row;
    bottom: 25px;
    right: -15px;
  }
  .intro-group {
    margin-right: 30px;
  }
  .burger-item {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 15px;
  }
  .burger-group-item {
    margin-bottom: 0px;
  }
  .burger-item ul {
    margin-bottom: 0px;
  }
  .sub-burger-item {
    display: none;
  }
  .content-main {
    max-width: 100%;
  }
  .section-header {
    left: 85px !important;
    top: 80vh !important;
  }
  .interface {
    padding-top: 80vh;
  }
  .content-fade {
    height: 120px;
  }
  .instructions-wrapper {
    top: calc(75vh - 50px);
  }
  .overlays .content-main {
    width: 150px;
  }
  .section-header.occupier-header {
    background-size: contain;
    width: 90px;
    height: 230px;
  }
  .occupiers-item {
    width: 33%;
  }

  .popup-overlay-wrapper {
    width: 400px;
  }

  .popup-overlay-wrapper .intro-para {
    font-size: 14px;
    line-height: 17px;
  }

  .popup-overlay-wrapper .copy {
    font-size: 12px;
    line-height: 14px;
  }

  .ui {
    width: 100%;
  }

  .section-header.specification-title {
    background-size: contain;
    width: 60px;
    height: 200px;
  }
  .schedule-diagram {
    display: none;
  }
}

@media (max-width: 680px) and (max-height: 568px) {
  .section-header {
    display: none;
  }

  .content-main {
    padding-left: calc(10vw + 39px) !important;
  }

  .occupiers-item {
    width: 50%;
  }
  .popup-overlay-image {
    display: none;
  }
}
